<template>
  <div class="container">
    <project-name title="体质健康" class="project-title" />
    <div
      v-if="getJiNengItems.length || getShiYingItems.length"
      class="content-mark"
    >
      <!-- 评分 -->
      <div v-if="getJiNengItems.length" class="left-rate">
        <span class="rate-title">参与与技能：</span>
        <TermRate :current="getJiNeng.Star" />
      </div>
      <div v-if="getShiYingItems.length" class="right-rate">
        <span class="rate-title">健康与适应：</span>
        <TermRate :current="getShiYing.Star" />
      </div>
    </div>
    <div class="page-content">
      <!-- 中间成绩/数据 -->
      <div v-if="studentHealth != null" class="left-body">
        <!-- 左侧 身体数据 -->
        <div v-if="Schoolid != '50'" class="body-section">
          <div class="first-row">
            身高(cm) &nbsp;&nbsp;{{ studentHealth.Stature }} &nbsp;&nbsp;评价
            &nbsp;&nbsp;{{ studentHealth.StatureEva }}
          </div>
          <div class="two-row">
            体重(kg) &nbsp;&nbsp;{{ studentHealth.Weight }} &nbsp;&nbsp;评价
            &nbsp;&nbsp;{{ studentHealth.WeightEva }}
          </div>
        </div>
        <div v-if="Schoolid != '50'" class="body-section">
          <div class="first-row">
            营养评价 &nbsp;&nbsp;{{ studentHealth.NutritionalEva }}
          </div>
          <div class="two-row">BMI指数 &nbsp;&nbsp;{{ studentHealth.BMI }}</div>
        </div>
        <div class="body-section">
          <div class="first-row">
            视力左 {{ studentHealth.VisionLeft }} &nbsp;&nbsp;|&nbsp;&nbsp;
            视力右
            {{ studentHealth.VisionRight }}
          </div>
          <div class="two-row">
            视力评价 &nbsp;&nbsp;{{ studentHealth.VisionEva }}
          </div>
        </div>
        <div v-if="Schoolid != '50'" class="body-section">
          <div class="first-row">
            牙齿 &nbsp;&nbsp;{{ studentHealth.Tooth }}
          </div>
          <div class="two-row">脊柱 &nbsp;&nbsp;{{ studentHealth.Spine }}</div>
        </div>
        <div v-if="Schoolid != '50'" class="body-section">
          <div class="first-row">
            肺活量(ml) &nbsp;&nbsp;{{ studentHealth.VitalCapacity }}
          </div>
          <div class="two-row">
            肺活量评价 &nbsp;&nbsp;{{ studentHealth.VitalCapacityEva }}
          </div>
        </div>
        <div v-if="Schoolid != '50'" class="body-bottom">
          肺活量体重指数 &nbsp;&nbsp;{{ studentHealth.VCBMI }}
        </div>
        <img :src="bagcImg" alt="#" class="content-left-mark" />
      </div>
      <img
        v-if="studentHealth != null"
        src="../assets/page7_left_right.png"
        alt="#"
        class="content-center-line"
      />
      <div class="right-score">
        <!-- 右侧体育成绩 -->
        <div class="score-title">本学年体测成绩</div>
        <div class="score-th">
          <!-- 各项标题 -->
          <div class="score-project">项目</div>
          <div class="score-num">成绩</div>
          <div class="score-level">等级</div>
        </div>
        <div v-for="item in staminaTest" :key="item.ItemName" class="score-tb">
          <!-- 数据 -->
          <div class="score-project">
            <img :src="item.ItemInfo.Icon" alt="#" class="score-project-img" />
            <span class="score-project-name">{{ item.ItemName }}</span>
          </div>
          <div class="score-num">{{ formartTime(item) }}</div>
          <div class="score-level">
            <span class="level-title">{{ item.ItemEva }}</span>
            <el-progress
              class="el-pro"
              :percentage="item.Star * 2 * 10.0"
              :stroke-width="11"
              :show-text="false"
              :color="customColors"
            ></el-progress>
          </div>
        </div>
      </div>
    </div>
    <div class="page-footer">
      <div v-if="studentHealth != null" class="date-mark">数据采集日期：{{studentHealth.InputDate}}</div>
      <!-- 页面底部 -->
      <div class="line-bottom"></div>
      <span class="bottom-desc"
        >注:BMI指数(英文为Body Mass
        Index，简称BMI)，是用体重千克数除以身高米数的平方得出的数字，是国
        际上常用的衡量人体胖瘦程度以及是否健康的一个标准。正常范围:18.5≤BMI&lt;23</span
      >
    </div>
    <img src="../assets/term/term_foot_bg.png" alt="#" class="footer-bgimg" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import TermRate from "../components/term/TermRate.vue";
import ProjectName from "../components/term/ProjectName.vue";
export default {
  components: {
    ProjectName,
    TermRate,
  },
  props: {
    studentHealth: {},
    staminaTest: [],
    ReportEvalSum: [],
    Schoolid: "",
  },
  computed: {
    ...mapState(["themType"]),
    getJiNengItems() {
      return this.ReportEvalSum.filter(
        (item) => item.Weidu == "参与与技能" && item.Course == "体育"
      );
    },
    getShiYingItems() {
      return this.ReportEvalSum.filter(
        (item) => item.Weidu == "健康与适应" && item.Course == "体育"
      );
    },
    getJiNeng() {
      return this.getJiNengItems[0];
    },
    getShiYing() {
      return this.getShiYingItems[0];
    },
    bagcImg() {
      if (typeof this.themType == "undefined") {
        return require("../assets/term/term_person.png");
      } else if (this.themType == "green") {
        return require("../assets/term/term_person.png");
      } else if (this.themType == "blue") {
        return require("../assets/term/term_person_blue.png");
      } else {
        return require("../assets/term/term_person_organe.png");
      }
    },
  },
  data() {
    return {
      customColors: [
        { color: "#ED4908", percentage: 60 },
        { color: "#EB848C", percentage: 80 },
        { color: "#62AADE", percentage: 100 },
        { color: "#7BE2D2", percentage: 100 },
      ],
    };
  },
  methods: {
    formartTime(item) {
      if (item.ItemInfo.Unit == "秒" || item.ItemInfo.Unit == "分") {
        var temp = item.ItemScore.toString().split(".");
        if (temp.length == 2) {
          if (item.ItemInfo.Unit == "秒") return temp[0] + "''" + temp[1];
          else if (item.ItemInfo.Unit == "分")
            return temp[0] + "'" + temp[1] + "''";
        } else {
          if (item.ItemInfo.Unit == "秒") return temp[0] + "''";
          else if (item.ItemInfo.Unit == "分") return temp[0] + "'";
        }
      }
      return item.ItemScore + item.ItemInfo.Unit;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border-radius: 13px;
  width: 722px;
  height: 1000px;
  margin: 0 auto;
  position: relative;

  .project-title {
    margin-top: 45px;
  }

  .content-mark {
    width: 684px;
    height: 53px;
    background: #f5f8fd;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #3e3a39;
    font-size: 16px;
    margin-top: 32px;

    .left-rate {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .right-rate {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .page-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    position: relative;
    margin-top: 32px;
    z-index: 99;
    .content-center-line {
      position: absolute;
      left: 311px;
      top: 0;
      transform: translateX(-100%);
      width: 40px;
      height: 600px;
    }

    .left-body {
      width: 311px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      font-size: 13px;
      position: relative;

      .content-left-mark {
        width: 188px;
        height: 533px;
        position: absolute;
        right: 23px;
        top: 63px;
        z-index: 0;
      }

      .body-section {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        padding: 8px;
        margin: 54px 54px 0 5px;
        box-sizing: border-box;
        z-index: 99;
        border-bottom: 1px solid #dbdcdc;
        width: 80%;
        box-sizing: border-box;

        .first-row {
          margin-left: 22px;
          line-height: 16px;
        }

        .two-row {
          margin-top: 5px;
          margin-left: 22px;
          line-height: 16px;
        }
      }

      .body-bottom {
        margin-top: 13px;
        margin-left: 30px;
        z-index: 99;
      }
    }

    .right-score {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .score-title {
        font-size: 21px;
        margin-top: 24px;
      }

      .score-th {
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 16px;
        width: 100%;
        height: 58px;
        border-top: 1px solid #dbdcdc;
        border-bottom: 1px solid #dbdcdc;
        margin-top: 36px;

        .score-project {
          flex: 0.5;
          padding-left: 12px;
        }

        .score-num {
          flex: 0.2;
          text-align: center;
        }

        .score-level {
          flex: 0.3;
          text-align: center;
        }
      }

      .score-tb {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 16px;
        width: 100%;
        height: 88px;
        border-bottom: 1px solid #dbdcdc;

        .score-project {
          flex: 0.5;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 12px;

          .score-project-img {
            width: 37px;
            height: 37px;
          }
        }

        .score-num {
          flex: 0.2;
          text-align: center;
        }

        .score-level {
          flex: 0.3;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }

        .el-pro {
          margin-top: 8px;
          width: 80px;
        }
      }
    }
  }

  .page-footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    z-index: 99;
    position: absolute;
    bottom: -50px;

    .date-mark {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-left: 60px;
      position: absolute;
      top: -50px;
      font-size: 16px;
      font-weight: 500;
    }

    .line-bottom {
      width: calc(100% - 46px);
      height: 69px;
      border-bottom: dashed 1px #2f774d;
    }

    .bottom-desc {
      font-size: 13px;
      line-height: 20px;
      color: #717071;
      margin: 27px 37px 57px 37px;
    }
  }

  .footer-bgimg {
    width: 717px;
    height: 133px;
    z-index: 1;
    object-fit: contain;
    position: absolute;
    bottom: 36px;
    left: 3px;
  }
}
</style>
