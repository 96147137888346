<template>
  <div class="login-main">
    <img src="../assets/term/tem_login_btbg.png" alt="#" class="bottom-img" />
    <img src="../assets/term/term_login_bg.png" alt="#" class="login-mark" />
    <div class="main-content-border">
      <!-- 登录框 -->
      <div class="main-content">
        <!-- 登录框 -->
        <span class="bg-title">素质报告系统</span>
        <div class="section-user">
          <el-input
            v-model="user"
            clearable
            prefix-icon="el-icon-user"
            placeholder="请输入学生名"
            class="user-name"
            @focus="focusing = true"
            @blur="focusing = false"
          ></el-input>
        </div>
        <div class="section-pass">
          <el-input
            v-model="pass"
            clearable
            show-password
            prefix-icon="el-icon-lock"
            placeholder="请输入密码"
            class="user-pass"
            @focus="focusing = true"
            @blur="focusing = false"
          ></el-input>
        </div>
        <el-button style="width: 100px;" round @click="login">登录</el-button>
      </div>
    </div>
    <div v-if="!focusing" class="bottom-tip">
      为达到最好的预览效果和打印报告<br />请用电脑登录打开此系统<br />网址：<span
        class="tip-url"
        >http://report.junmao.top</span
      >
    </div>
  </div>
</template>

<script>
import { loginReport } from "@/api/term";
import { mapMutations, mapActions } from "vuex";
export default {
  data() {
    return {
      user: "",
      pass: "",
      focusing: false,
    };
  },
  created() {},
  methods: {
    ...mapActions(["setStuMap"]),
    ...mapMutations(["changeStuMap"]),
    login() {
      if (this.user.length < 1) {
        this.$message.error("请输入正确学生名");
        return;
      }
      if (this.pass.length < 1) {
        this.$message.error("请输入正确密码");
        return;
      }
      loginReport({
        StuName: this.user,
        StuPassword: this.pass,
      }).then((res) => {
        if (res.code == 0) {
          let no = this.getStudents(res.data);
        } else {
          this.$message.error(res.msg ? res.msg : "用户或密码错误");
        }
      });
    },
    getStudents(datas) {
      this.changeStuMap({ map: JSON.stringify(datas) });
      this.$router.push({
        name: "termHome",
        query: { StuNo: datas[0][0].student.StudentNo, AcdemicYear: datas[0][0].AcdemicYear, Semester: datas[0][0].Semester },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login-main {
  background: linear-gradient(180deg, #23037a 0%, #0436ef 100%);
  width: 100vw;
  height: 100vh;
  position: relative;

  .main-content-border {
    border-radius: 22px;
    border: 1px solid #6674ec;
    position: absolute;
    left: 10vw;
    top: 50vh;
    transform: translateY(-50%);

    .main-content {
      border-radius: 22px;
      border: 1px solid #ffffff;
      width: 30vw;
      height: 30vw;
      margin: 7px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;

      .bg-title {
        font-size: 2rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        margin-top: 36px;
      }

      .section-user {
        width: 20vw;
        .user-name {
          background-color: rgba(1, 1, 1, 1);
        }
      }
      .section-pass {
        width: 20vw;
      }
    }
  }

  .bottom-img {
    object-fit: cover;
    width: 100vw;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .login-mark {
    object-fit: contain;
    position: absolute;
    right: 10vw;
    bottom: 18px;
    width: 40vw;
    height: 90vh;
  }

  .bottom-tip {
    display: none;
  }

  @media screen and (max-width: 750px) {
    .main-content-border {
      border-radius: 22px;
      border: 1px solid #6674ec;
      width: 80vw;
      height: 80vw;
      position: absolute;
      bottom: 10vh;
      left: 50%;
      transform: translate(-50%, -50%);

      .main-content {
        border-radius: 22px;
        border: 1px solid #ffffff;
        width: calc(80vw - 14px);
        height: calc(80vw - 14px);
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;

        .bg-title {
          font-size: 2rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          margin-top: 36px;
        }

        .section-user {
          width: 70vw;
          .user-name {
            background-color: rgba(1, 1, 1, 1);
          }
        }
        .section-pass {
          width: 70vw;
        }
      }
    }

    .login-mark {
      display: none;
    }

    .bottom-tip {
      display: block;
      color: white;
      position: absolute;
      bottom: 10vh;
      width: 100vw;
      text-align: center;
    }
  }
}
</style>
